import React, { Suspense } from 'react';
import { Route, Routes, BrowserRouter as Routers } from 'react-router-dom';
import MainLayout from '../Layout/MainLayout';
import PageLoding from '../components/PageLoding';

const Home = React.lazy(() => import('../view/index'));
const IdoIndex = React.lazy(() => import('../view/ido'));
const IdoBuyDetail = React.lazy(() => import('../view/ido/buyDetail'));
const My = React.lazy(() => import('../view/my/index'));
const MyWhiteList = React.lazy(() => import('../view/my/whiteList/index'));
const Mint = React.lazy(() => import('../view/mint/index'));
const MintDetail = React.lazy(() => import('../view/mint/detail/index'));
const Exchang = React.lazy(() => import('../view/exchang/index'));
const Staking = React.lazy(() => import('../view/staking/index'));
const Market = React.lazy(() => import('../view/market/index'));
const Depin = React.lazy(() => import('../view/depin/index'));
const ListingDetails = React.lazy(() => import('../view/my/listingDetails'));
const OrderDetails = React.lazy(() => import('../view/my/orderDetails'));

export default function Router() {
    return (
        <Suspense fallback={<PageLoding></PageLoding>}>
            <Routes>
                <Route path="/*" element={<MainLayout />}>
                    {/* 首页 */}
                    <Route path="" element={<Home />} />
                    <Route path="ido" element={<IdoIndex />} />
                    <Route path="ido/buyDetail" element={<IdoBuyDetail />} />
                    <Route path="my" element={<My />} />
                    <Route path="my/whiteList" element={<MyWhiteList />} />
                    {/* 上架详情 */}
                    <Route path="my/listingDetails" element={<ListingDetails />} />
                    {/* 订单详情 */}
                    <Route path="my/orderDetails" element={<OrderDetails />} />

                    <Route path="mint" element={<Mint />} />
                    <Route path="mint/detail" element={<MintDetail />} />

                    <Route path="exchange" element={<Exchang />} />
                    <Route path="staking" element={<Staking />} />
                    <Route path="market" element={<Market />} />
                    <Route path="depin" element={<Depin />} />
                </Route>
            </Routes>
        </Suspense>
    );
}
