const windowHost = window.location.origin;

export const LOCAL_KEY = 'MBAS_LANG';

let pattern = /^(http:\/\/localhost|http:\/\/192\.168|https:\/\/localhost|https:\/\/192\.168|file:\/\/*)/i;
// 判断环境
export const isMain = pattern.test(windowHost) ? false : true; //上线需打开
// export const isMain = false; //本地测试
// 请求api
export let baseUrl: string = isMain ? 'https://infinitealliance.biz/api' : 'https://ustest.infinitealliance.biz/api';

// 合约相关配置
export const LOCAL_RPC = isMain
    ? 'https://mainnet.helius-rpc.com/?api-key=34778cf4-2c5b-4584-ad71-a157b7e14eb5'
    : 'https://api.devnet.solana.com/'; // rpc
// https://api.devnet.solana.com/
export const LOCAL_programId = isMain
    ? 'YGk7dtc8tUnJZrahgCdiJs6wczFxM6Uu1uQpwXHFKWA'
    : '9tEu4TtZa8fSSSN49ybsedLkq3BCDD4EU1STwf5ds4tE'; // programId
export const usdt_mint = isMain
    ? 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB'
    : 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB'; // usdt
export const usdt_receiver = isMain
    ? 'EfgB6dCfZZDzYdW1QMVfVqnFotCvVHqt8FmWXKZa2RxX'
    : 'HEtZXCWrbgHtdEB8k3eHgEwXatjzdV3uAk6KxZYQ3hfW'; // to address
