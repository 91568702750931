import React, { useEffect } from 'react';
import protocol from './h5.module.css';
import logo from '../../assets/image/h5/logo.png';
import { Dropdown, notification } from 'antd';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { AddrHandle, getTopBarIndex, setTopBarIndex } from '../../utils/tool';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LOCAL_KEY } from '../../config';

const ComponentName = (props?: {}) => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const [showList, setShowList] = React.useState(false);

    const { setVisible } = useWalletModal();
    const { publicKey, signMessage, disconnect } = useWallet();
    const navigate = useNavigate();
    const handleConnect = () => {
        if (!publicKey) {
            setVisible(true);
        } else {
            // disconnect();
        }
    };

    const location = useLocation();
    const handleSetTabIndex = () => {
        switch (location.pathname) {
            case '/':
                setTabIndex(0);
                break;
            case '/ido':
                setTabIndex(1);
                break;
            case '/my':
                setTabIndex(6);
                break;
            case '/mint':
                setTabIndex(2);
                break;
            case '/exchange':
                setTabIndex(3);
                break;
            case '/staking':
                setTabIndex(4);
                break;
            case '/market':
                setTabIndex(5);
                break;
            case '/depin':
                setTabIndex(6);
                break;
            default:
                setTabIndex(-2);
        }
    };

    useEffect(() => {
        handleSetTabIndex();
        // 添加窗口大小变化的事件监听器
        window.addEventListener('resize', handleResize);
        // 清理函数，移除事件监听器
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleResize = () => {
        try {
            setTabIndex(Number(getTopBarIndex()) || 0);
        } catch (error) {
            console.error('Error in handleResize:', error);
        }
        setShowList(false);
    };

    const { t, i18n } = useTranslation();

    function changeLanguage(key: string) {
        window.localStorage.setItem(LOCAL_KEY, key);
        i18n.changeLanguage(key);
    }
    const items = [
        // {
        //     key: '1',
        //     label: (
        //         <div
        //             style={{ color: i18n.language === 'zh' ? '#000' : '#999', fontWeight: 600 }}
        //             onClick={() => changeLanguage('zh')}
        //         >
        //             中文简体
        //         </div>
        //     ),
        // },
        {
            key: '2',
            label: (
                <div style={{ color: i18n.language === 'en' ? '#000' : '#999' }} onClick={() => changeLanguage('en')}>
                    English
                </div>
            ),
        },
    ];

    return (
        <div className={protocol.main}>
            <div className={protocol.main_box}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={protocol.main_box_right}>
                        <svg
                            onClick={() => {
                                setShowList(!showList);
                            }}
                            className={`${protocol.mr_10} ${showList ? 'rotate-90' : 'rotate-0'} pointer`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                        >
                            <path
                                d="M11.1405 7.22281H25.8599C26.4895 7.22281 27 6.72521 27 6.1114C27 5.4976 26.4895 5 25.8599 5H11.1405C10.5108 5 10.0003 5.4976 10.0003 6.1114C10.0003 6.72521 10.5108 7.22281 11.1405 7.22281ZM11.1401 16.0868H25.8055C26.4352 16.0868 26.9457 15.5892 26.9457 14.9754C26.9457 14.3616 26.4352 13.864 25.8055 13.864H11.1401C10.5105 13.864 10 14.3616 10 14.9754C10 15.5892 10.5105 16.0868 11.1401 16.0868ZM25.8599 22.7772H11.1405C10.5108 22.7772 10.0003 23.2748 10.0003 23.8886C10.0003 24.5024 10.5108 25 11.1405 25H25.8599C26.4895 25 27 24.5024 27 23.8886C27 23.2748 26.4895 22.7772 25.8599 22.7772Z"
                                fill="white"
                            />
                            <path
                                d="M5 6C5 6.53043 5.21071 7.03914 5.58579 7.41421C5.96086 7.78929 6.46957 8 7 8C7.53043 8 8.03914 7.78929 8.41421 7.41421C8.78929 7.03914 9 6.53043 9 6C9 5.46957 8.78929 4.96086 8.41421 4.58579C8.03914 4.21071 7.53043 4 7 4C6.46957 4 5.96086 4.21071 5.58579 4.58579C5.21071 4.96086 5 5.46957 5 6Z"
                                fill="white"
                            />
                            <path
                                d="M5 15C5 15.5304 5.21071 16.0391 5.58579 16.4142C5.96086 16.7893 6.46957 17 7 17C7.53043 17 8.03914 16.7893 8.41421 16.4142C8.78929 16.0391 9 15.5304 9 15C9 14.4696 8.78929 13.9609 8.41421 13.5858C8.03914 13.2107 7.53043 13 7 13C6.46957 13 5.96086 13.2107 5.58579 13.5858C5.21071 13.9609 5 14.4696 5 15Z"
                                fill="white"
                            />
                            <path
                                d="M5 24C5 24.5304 5.21071 25.0391 5.58579 25.4142C5.96086 25.7893 6.46957 26 7 26C7.53043 26 8.03914 25.7893 8.41421 25.4142C8.78929 25.0391 9 24.5304 9 24C9 23.4696 8.78929 22.9609 8.41421 22.5858C8.03914 22.2107 7.53043 22 7 22C6.46957 22 5.96086 22.2107 5.58579 22.5858C5.21071 22.9609 5 23.4696 5 24Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <img
                        src={logo}
                        style={{ width: '40px', height: '26px' }}
                        onClick={() => {
                            setTabIndex(0);
                            navigate('/');
                            setTopBarIndex('0');
                        }}
                        alt="logo"
                    />
                    <div style={{ flex: 1 }}></div>
                    <div className={protocol.main_box_right}>
                        <Dropdown menu={{ items }} placement="bottom" arrow trigger={['click']}>
                            <svg
                                className="pointer"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 30 31"
                                fill="none"
                            >
                                <path
                                    d="M15 2.53516C7.82595 2.53516 2 8.36111 2 15.5352C2 22.7092 7.82595 28.5352 15 28.5352C22.174 28.5352 28 22.7092 28 15.5352C28 8.36111 22.174 2.53516 15 2.53516ZM26.0259 14.5725H22.6557C22.5114 11.0092 21.5481 7.87946 20.0557 5.71301C21.721 6.56165 23.1428 7.82023 24.1873 9.37018C25.2318 10.9201 25.8645 12.7105 26.0259 14.5725ZM14.0373 4.60541V14.5732H9.27025C9.46265 9.61366 11.4854 5.47316 14.0373 4.60606V4.60541ZM14.0373 16.4978V26.4649C11.4854 25.5978 9.46265 21.4573 9.27025 16.4978H14.0373ZM15.9626 26.4649V16.4972H20.7297C20.5373 21.4567 18.5145 25.5972 15.9626 26.4643V26.4649ZM15.9626 14.5725V4.60541C18.5145 5.47251 20.5373 9.61301 20.7297 14.5725H15.962H15.9626ZM9.8962 5.71301C8.4038 7.87946 7.4405 11.0092 7.2962 14.5725H3.92595C4.3114 10.7206 6.62215 7.39781 9.8962 5.71301ZM3.97405 16.4972H7.3443C7.4886 20.0605 8.4519 23.1902 9.9443 25.3567C8.27904 24.508 6.8572 23.2494 5.81272 21.6995C4.76823 20.1495 4.13548 18.3592 3.97405 16.4972ZM20.1038 25.3567C21.5962 23.1902 22.5595 20.0605 22.7038 16.4972H26.0741C25.6886 20.3491 23.3779 23.6719 20.1038 25.3567Z"
                                    fill="white"
                                />
                            </svg>
                        </Dropdown>
                        <div className={`${protocol.main_box_wallet}`} onClick={() => handleConnect()}>
                            {publicKey ? AddrHandle(publicKey?.toBase58(), 6, 4) : t('Please link wallet')}
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    position: 'fixed',
                    top: '66px',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: showList ? 'block' : 'none',
                    background: 'rgba(255, 255, 255, 0.01)',
                    backdropFilter: 'blur(2.5px)',
                    zIndex: 99,
                }}
                onClick={() => setShowList(false)}
            >
                <div className={`${protocol.main_box_box}`}>
                    <div className={`${protocol.main_box_right_item}`}>
                        <div className={`${tabIndex === 0 ? protocol.main_box_right_item_on : ''}`}>
                            <div
                                className={`${protocol.main_box_right_item_on_bg} ${
                                    tabIndex === 0 ? protocol.main_box_right_item_on_bg2 : ''
                                }`}
                                onClick={() => {
                                    setTabIndex(0);
                                    navigate('/');
                                    setTopBarIndex('0');
                                }}
                            >
                                {t('1')}
                            </div>
                        </div>
                    </div>
                    <div className={`${protocol.main_box_right_item}`}>
                        <div className={`${tabIndex === 2 ? protocol.main_box_right_item_on : ''}`}>
                            <div
                                className={`${protocol.main_box_right_item_on_bg} ${
                                    tabIndex === 2 ? protocol.main_box_right_item_on_bg2 : ''
                                }`}
                                onClick={() => {
                                    setTabIndex(2);
                                    navigate('/mint');
                                    setTopBarIndex('2');
                                }}
                            >
                                {t('2')}
                            </div>
                        </div>
                    </div>
                    <div className={`${protocol.main_box_right_item}`}>
                        <div className={`${tabIndex === 1 ? protocol.main_box_right_item_on : ''}`}>
                            <div
                                className={`${protocol.main_box_right_item_on_bg} ${
                                    tabIndex === 1 ? protocol.main_box_right_item_on_bg2 : ''
                                }`}
                                onClick={() => {
                                    setTabIndex(1);
                                    navigate('/ido');
                                    setTopBarIndex('1');
                                }}
                            >
                                {t('3')}
                            </div>
                        </div>
                    </div>
                    <div className={`${protocol.main_box_right_item}`}>
                        <div className={`${tabIndex === 3 ? protocol.main_box_right_item_on : ''}`}>
                            <div
                                className={`${protocol.main_box_right_item_on_bg} ${
                                    tabIndex === 3 ? protocol.main_box_right_item_on_bg2 : ''
                                }`}
                                onClick={() => {
                                    setTabIndex(3);
                                    navigate('/exchange');
                                    setTopBarIndex('3');
                                }}
                            >
                                {t('4')}
                            </div>
                        </div>
                    </div>
                    <div className={`${protocol.main_box_right_item}`}>
                        <div className={`${tabIndex === 4 ? protocol.main_box_right_item_on : ''}`}>
                            <div
                                className={`${protocol.main_box_right_item_on_bg} ${
                                    tabIndex === 4 ? protocol.main_box_right_item_on_bg2 : ''
                                }`}
                                onClick={() => {
                                    setTabIndex(4);
                                    navigate('/staking');
                                    setTopBarIndex('4');
                                }}
                            >
                                {t('5')}
                            </div>
                        </div>
                    </div>
                    <div className={`${protocol.main_box_right_item}`}>
                        <div className={`${tabIndex === 5 ? protocol.main_box_right_item_on : ''}`}>
                            <div
                                className={`${protocol.main_box_right_item_on_bg} ${
                                    tabIndex === 5 ? protocol.main_box_right_item_on_bg2 : ''
                                }`}
                                onClick={() => {
                                    setTabIndex(5);
                                    navigate('/market');
                                    setTopBarIndex('5');
                                }}
                            >
                                {t('6')}
                            </div>
                        </div>
                    </div>
                    <div className={`${protocol.main_box_right_item}`}>
                        <div className={`${tabIndex === 6 ? protocol.main_box_right_item_on : ''}`}>
                            <div
                                className={`${protocol.main_box_right_item_on_bg} ${
                                    tabIndex === 6 ? protocol.main_box_right_item_on_bg2 : ''
                                }`}
                                onClick={() => {
                                    // setTabIndex(6);
                                    // navigate('/depin');
                                    notification.open({
                                        key: 'Commit Soon',
                                        message: 'Commit Soon',
                                        duration: 1,
                                    });
                                }}
                            >
                                {t('7')}
                            </div>
                        </div>
                    </div>
                    <div className={`${protocol.main_box_right_item}`}>
                        <div className={`${tabIndex === 7 ? protocol.main_box_right_item_on : ''}`}>
                            <div
                                className={`${protocol.main_box_right_item_on_bg} ${
                                    tabIndex === 7 ? protocol.main_box_right_item_on_bg2 : ''
                                }`}
                                onClick={() => {
                                    setTabIndex(7);
                                    navigate('/my');
                                    setTopBarIndex('7');
                                }}
                            >
                                {t('8')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComponentName;
